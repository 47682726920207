<template>
  <div class="box">
     <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <user-form v-if="model"
               :submit-text="pageTitle"
               :model="model"
               :isEdit="true"
               @on-submit="createModel">
    </user-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import UserForm from './_Form'
import UserService from '@admin/services/UserService'
export default {
  components: { UserForm, PlaceholderForm },
  data () {
    return {
      pageTitle: '编辑会员等级',
      model: null
    }
  },
  async created () {
    const { data } = await flatry(UserService.levelEdit(this.$router.currentRoute.query.id))

    if (data) {
      this.model = data.data
      if (data.data.member_body == null) {
        this.model.member_body = [
          {
            title: '',
            body: ''
          }
        ]
      }
    }
  },
  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(UserService.levelEdit(model.id, model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/level/update', query: { id: data.data } })
        success()
      }

      callback()
    }
  }

}

</script>
<style lang='' scoped>

</style>
