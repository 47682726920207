import Vue from 'vue'

const UserService = {
  // 所有用户端小程序用户
  all (query) {
    return Vue.http.get('user/index', query)
  },

  // 用户等级
  level (query) {
    return Vue.http.get('user/level', query)
  },

  // 查看用户信息
  view (id) {
    const endpoint = 'user/view'
    return Vue.http.post(endpoint, { id: id })
  },
  // 查询用户订单
  order (param) {
    return Vue.http.post('user/order', param)
  },
  // 等级会员规则
  levelCreate (user = null) {
    const endpoint = 'user/level-create'

    if (user === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, user)
  },

  levelEdit (id, user = null) {
    const endpoint = 'user/level-edit'

    if (user === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.put(endpoint, user, { id: id })
  },
  levelDel (id) {
    return Vue.http.delete('user/level-delete', id)
  },

  // 金钱积分调整
  changeData (param, get = null) {
    const endpoint = 'user/change-data'

    if (get === null) {
      return Vue.http.post(endpoint, param)
    }

    return Vue.http.get(endpoint, param)
  },
  // 禁用启用按钮
  Prohibit (param) {
    return Vue.http.put('user/prohibit', param)
  },
  allExport (param) {
    return Vue.http.get('user/export', param)
  },
  deleteAll (param) {
    return Vue.http.delete('user/export-all', param)
  },
  deleteE (param) {
    return Vue.http.delete('user/export-del', param)
  }
}

export default UserService
